<template>
  <w-form v-model="valid">
    <w-flex wrap>
      <div class="xs6">
        <w-select
          class="ma3"
          :items="titles"
          v-model="person.title"
          item-value-key="id"
          label="Title"
        />
      </div>
      <div class="xs6">
        <w-input
          class="ma3"
          v-model="person.initials"
          label="Initials"
          :validators="[validators.noNumbers]"
          maxlength="5"
        />
      </div>
      <div class="xs6">
        <w-input
          class="ma3"
          v-model="person.firstName"
          label="First Name"
          :validators="[validators.noNumbers]"
          maxlength="100"
        />
      </div>
      <div class="xs6">
        <w-input
          class="ma3"
          v-model="person.lastName"
          label="Last Name"
          :validators="[validators.noNumbers]"
          maxlength="100"
        />
      </div>
      <div class="xs6">
        <w-input
          class="ma3"
          v-model="person.email"
          type="email"
          label="Email"
        />
      </div>

      <div class="xs6 mb3">
        <w-button
          :loading="searchInProgress"
          type="submit"
          :disabled="valid === false"
          @click="$emit('person-search', person)"
          ><span class="title2">Search</span></w-button
        >
      </div>
    </w-flex>
  </w-form>
</template>

<script>
import PersonTitle from "@/model/PersonTitle.js";

export default {
  name: "PeopleSearchForm",
  props: {
    criteria: Object,
    searchInProgress: Boolean
  },
  emits: ["person-search"],
  data() {
    return {
      person: {},
      titles: PersonTitle.asList(),
      valid: null,
      validators: {
        onlyNumbers: value =>
          value === "" ||
          value === null ||
          value.match(/^[0-9]+$/) != null ||
          "Only numbers are allowed.",
        noNumbers: value =>
          value === "" ||
          value === null ||
          value.match(/^\D*$/) ||
          "No numbers are allowed."
      },
      notification: {
        show: false,
        successful: false,
        unsuccessful: false,
        timeout: 2000
      }
    };
  },
  created() {
    if (this.criteria) {
      this.person.firstName = this.criteria.firstName?.substr(0, 100);
      this.person.lastName = this.criteria.lastName?.substr(0, 100);
      this.person.title = this.criteria.title;
      this.person.email = this.criteria.email?.substr(0, 150);
      this.person.initials = this.criteria.initials?.substr(0, 5);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button[type="submit"] {
  height: 100%;
  width: 100%;
  display: block;
}
</style>
