<template>
  <div class="ma4">
    <PeopleSearchForm
      :searchInProgress="searchInProgress"
      :criteria="searchCriteria"
      @person-search="search($event)"
    ></PeopleSearchForm>
    <PeopleSearchResults
      :criteria="searchCriteria"
      @people-loading="updateSearchStatus($event)"
      @person-select="navigateToPersonView($event)"
    ></PeopleSearchResults>
  </div>
</template>
<script>
import PeopleSearchForm from "../components/PeopleSearchForm.vue";
import PeopleSearchResults from "../components/PeopleSearchResults.vue";
import router from "../router";
import PersonTitle from "@/model/PersonTitle.js";
export default {
  components: { PeopleSearchForm, PeopleSearchResults },
  name: "PeopleEntryView",
  data: () => {
    return {
      searchCriteria: {},
      searchInProgress: false
    };
  },
  created() {
    this.search(this.$route.query);
  },
  methods: {
    navigateToPersonView: function(personId) {
      router.push({ name: "PeopleEntryView", params: { id: personId } });
    },
    updateSearchStatus: function(status) {
      this.searchInProgress = status;
    },
    search: function(criteria) {
      const searchCriteria = {
        firstName: criteria.firstName,
        lastName: criteria.lastName,
        title: criteria.title ? PersonTitle.fromId(criteria.title)?.id : null,
        email: criteria.email,
        initials: criteria.initials
      };
      this.searchCriteria = searchCriteria;
      router.push({ name: "PeopleFinderView", query: searchCriteria });
    }
  }
};
</script>
