<template>
  <w-notification
    v-model="state.error"
    :timeout="2000"
    error
    plain
    round
    shadow
    top
    center
    transition="bounce"
    >An error occured!</w-notification
  >
  <w-table
    :fixed-headers="true"
    :mobile-breakpoint="600"
    :headers="table.headers"
    :items="table.items"
    :loading="state.loading"
    select-row="select-row"
    @row-select="$emit('person-select', $event.item.id)"
  ></w-table>
</template>
<script>
import PeopleService from "../services/PeopleService.js";

function searchCriteriaChanged(searchCriteria, oldSearchCriteria) {
  for (const property in searchCriteria) {
    if (oldSearchCriteria[property] != searchCriteria[property]) {
      return true;
    }
  }
  return false;
}

export default {
  emits: ["person-select", "people-loading"],
  name: "PeopleSearchResult",
  props: {
    criteria: Object
  },
  created() {
    this.searchPeople(this.criteria);
  },
  data: () => ({
    table: {
      headers: [
        { label: "Title", key: "title" },
        { label: "Initials", key: "initials" },
        { label: "First name", key: "firstName" },
        { label: "Last name", key: "lastName" },
        { label: "Email", key: "email" }
      ],
      loading: false,
      items: [],
      sort: "+title"
    },
    state: {
      previousSearchFailed: false,
      loading: false,
      error: false
    }
  }),
  methods: {
    updateState(loading, error, previousSearchFailed) {
      this.state.loading = loading;
      this.state.error = error;
      this.state.previousSearchFailed = previousSearchFailed;
      this.$emit("people-loading", this.state.loading);
    },
    searchPeople(criteria) {
      this.updateState(true, false, this.state.previousSearchFailed);
      PeopleService.getPersons(criteria)
        .then(response => {
          this.table.items = response.data;
          this.updateState(false, false, false);
        })
        .catch(() => {
          this.updateState(false, true, true);
        });
    }
  },
  watch: {
    criteria: function(searchCriteria, oldSearchCriteria) {
      if (
        this.state.previousSearchFailed ||
        searchCriteriaChanged(searchCriteria, oldSearchCriteria)
      ) {
        this.searchPeople(searchCriteria);
      }
    }
  }
};
</script>
